import VerticalAnchorsMenu_VerticalAnchorsMenuLinkedNoTextSkinComponent from '@wix/thunderbolt-elements/src/components/VerticalAnchorsMenu/viewer/skinComps/VerticalAnchorsMenuLinkedNoTextSkin/VerticalAnchorsMenuLinkedNoTextSkin.skin';


const VerticalAnchorsMenu_VerticalAnchorsMenuLinkedNoTextSkin = {
  component: VerticalAnchorsMenu_VerticalAnchorsMenuLinkedNoTextSkinComponent
};


export const components = {
  ['VerticalAnchorsMenu_VerticalAnchorsMenuLinkedNoTextSkin']: VerticalAnchorsMenu_VerticalAnchorsMenuLinkedNoTextSkin
};


// temporary export
export const version = "1.0.0"
